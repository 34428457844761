<template>
  <div class="page flex-col">
    <div class="block_1 flex-col">
      <div class="section_1 flex-col">
        <div class="block_2 flex-row">
          <div class="block_3 flex-col">
            <img :src="constants.imagePathUrl" alt="">
          </div>
          <div class="block_4 flex-col">
            <span class="text_1">{{constants.shopName}}</span>
            <div class="image-wrapper_1 flex-row justify-between">
              <img
                class="label_1"
                referrerpolicy="no-referrer"
                src="./assets/img/0fb30ab3a9998734f97cac6544aa5627.png"
              />
              <img
                class="label_2"
                referrerpolicy="no-referrer"
                src="./assets/img/9aa92e020cc2ac9143b409fc6f3b6da1.png"
              />
              <img
                class="label_3"
                referrerpolicy="no-referrer"
                src="./assets/img/b7cdedaec703dc6dd6dceebd3264dea5.png"
              />
              <img
                class="label_4"
                referrerpolicy="no-referrer"
                src="./assets/img/30f58c1e418c30d1bc596f35bd8f93c9.png"
              />
              <img
                class="label_5"
                referrerpolicy="no-referrer"
                src="./assets/img/e7da7f752d749fba06f4b4ac9eaf7506.png"
              />
            </div>
            <div class="block_5 flex-row justify-between">
              <div class="text-wrapper_1 flex-col"><span class="text_2">营业中</span></div>
              <span class="text_3">{{constants.shopHours}}</span>
            </div>
          </div>
         <!-- <div class="text-wrapper_2 flex-col justify-between">
            <span class="text_4">169</span>
            <span class="text_5">养生积分</span>
          </div>-->
        </div>
        <div class="text-wrapper_3 flex-row"><span class="text_6">地址：{{constants.shopAddress}}</span></div>
      </div>
      <div class="section_2 flex-col">
        <span class="text_7">提现积分</span>
        <div class="text-wrapper_4 flex-row">
          <span class="text_8">￥</span>
          <input type="number" class="text_9" placeholder="请输入提现积分" v-model="txjf"/>
          <!--<span class="text_9">请输入提现积分</span>-->
          <span class="text_10" @click="quanbu()">全部提现</span>
        </div>
        <div class="group_1 flex-col"></div>
        <span class="text_11">当前可提现积分(养生积分)&nbsp;{{healthIntegral}}</span>
      </div>
      <div class="text-wrapper_5 flex-col" @click="scanCodeTx()">
        <span class="text_12">提交</span></div>
    </div>
    <div class="block_6 flex-row">
      <div class="group_2 flex-row">
        <img @click="onClickLeft"
          class="label_7"
          referrerpolicy="no-referrer"
          src="./assets/img/6ab145fc67a1d1456e97317df95954cd.png"
        />
        <span class="text_17">扫码提现</span>
      </div>
    </div>
  </div>
</template>
<script>
  import {queryShopList} from "@/api/home";
  import {queryUserByAccount,scanCodeTx} from "@/api/user";
  import axios from 'axios'

  export function getQueryObject(url) {
    url = url == null ? window.location.href : url
    const search = url.substring(url.lastIndexOf('?') + 1)
    const obj = {}
    const reg = /([^?&=]+)=([^?&=]*)/g
    search.replace(reg, (rs, $1, $2) => {
      const name = decodeURIComponent($1)
      let val = decodeURIComponent($2)
      val = String(val)
      obj[name] = val
      return rs
    })
    return obj
  }

export default {
  data() {
    return {
      baseURL: axios.defaults.baseURL,
      constants: {},
      shopParam: {
        shopCode: getQueryObject(window.location.href).shopCode,
        dr: 1
      },
      memberId:'',
      memberAccount: '',
      healthIntegral:0,
      foodIntegral:0,
      txjf:'',
      SysIntegralRecord:{}
    };
  },
  created() {
    const user = localStorage.getItem('userInfoDs')
    if(user){
      this.memberId = JSON.parse(user).memberId
      this.memberAccount = JSON.parse(user).account
      queryUserByAccount(this.memberAccount).then(response => {
        if (response.code == 200) {
          this.foodIntegral = response.data.foodIntegral  //美食积分
          this.healthIntegral = response.data.healthIntegral // 养生积分
        }else{
          this.$router.push('/login')
        }
      });
      queryShopList(this.shopParam).then(response => {
        if(response.code==200){
          this.constants = response.data[0]
          this.constants.imagePathUrl = this.baseURL+this.constants.imagePath
        }
      });
    }else{
      this.$router.push('/login')
    }

  },
  methods: {
    quanbu() {
      this.txjf = this.healthIntegral
    },
    scanCodeTx(){
      this.SysIntegralRecord.account = this.memberAccount
      this.SysIntegralRecord.shopId = this.constants.shopId
      this.SysIntegralRecord.integralValue = this.txjf
      scanCodeTx(this.SysIntegralRecord).then(response => {
        this.$model({
          show: true,
          title: "提示",
          content: response.msg,
          confirmButton: false,
          cancelButton: true
        });
        if (response.code == 200) {
          this.$router.push('/wode')
        }
      });
    },
    onClickLeft() {
      this.$router.push('/wode')
    },
  }
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />